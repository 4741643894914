<template>
  <dashPage :title="$t('NewsletterDash')"
            :subtitle="$t('AllNewslettters')"
            icon="mdi-email"
            no-side >

    <template #header_action>
      <v-btn @click="openNewNewsletter" class="noCaps" dark :color="wsACCENT"><v-icon>mdi-plus</v-icon>{{ $t('AddNewsletter') }}</v-btn>
    </template>

    <template #default>
      <v-sheet class="wsRoundedHalf pa-6 mt-6">
        <v-data-table :items="items" :headers="headers">
          <template v-slot:item="{item}">
            <tr>
              <td><h5>{{ item.name }}</h5></td>
              <td width="10px" class="text-no-wrap">
                <ws-chip v-if="item.receivers_type" icon="mdi-account-multiple" :text="$t('receivers_' + item.receivers_type)"></ws-chip>
              </td>
              <td width="10px" class="text-no-wrap">
                <wsChip v-if="item.date_published" icon="mdi-calendar" :text="PARSE_TIME_STAMP(item.date_published)"></wsChip>
              </td>
              <td width="10px" >
                <v-chip small outlined :color="!item.is_published ? 'grey' : wsACCENT ">
                  {{ item.is_published ? $t('Published') : $t('Draft')  }}
                </v-chip>
              </td>
              <td width="10px">
                <v-btn :to="localeLink('dashboard/newsletter/' + item.uuid)" small class="noCaps" dark :color="wsATTENTION">
                  {{ $t('Goto') }}
                  <v-icon small>mdi-chevron-right</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-sheet>
    </template>

    <template #dialog>
      <wsDialog v-model="displayDialog"
                :title="$t('AddNewsletter')"
                @save="addNewsletter">

        <h5 class="mb-3">{{$t('Name')}}</h5>
        <v-text-field v-model="entityData.name"
                      dense outlined
                      :placeholder="$t('Name')"
                      class="mb-3"/>

      </wsDialog>
    </template>

  </dashPage>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "AdminNewsletter",
  data() {
    return {
      displayDialog : false,
      entityData : {  },
      defaultEntityData : {},
      items : []
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Name')          , value : 'name'          },
        { text : this.$t('ReceiversType') , value : 'receivers_type' },
        { text : this.$t('Published')     , value : 'date_published' },
        { text : this.$t('Status')        , value : 'is_published'   },
        { value : 'action'  , sort : false  },
      ]
    }
  },
  methods : {
    ...mapActions('newsletter', [
      'GET_ADMIN_NEWSLETTERS',
      'ADD_ADMIN_NEWSLETTER',
    ]),

    async addNewsletter() {
      let result = await this.ADD_ADMIN_NEWSLETTER(this.entityData)
      if ( !result ) { return }
      this.items.push(result)
      this.displayDialog = false
      this.notify(this.$t('NewsletterCreated'))
    },

    openNewNewsletter() {
      this.entityData = JSON.parse(JSON.stringify(this.defaultEntityData))
      this.displayDialog = true
    },

    async initPage() {
      let result = await this.GET_ADMIN_NEWSLETTERS()
      if ( !result || result === true) { return }
      this.items = result
      this.displayDialog = false
    }

  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>